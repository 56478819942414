html {
    background-color:rgb(21, 32, 43);
}

body {
    /* background: url(/Assets/background.png);    */
    margin: 0 auto;
    padding: 0;
}

p {
    word-wrap: break-word;
}

div.MuiMenu-root, div.MuiMenu-paper {
    background-color: rgb(21, 32, 43);
    border: 1px solid rgb(21, 32, 43);
}

header a {
     text-decoration: none;
     color: white;
     float: left;
}

a { 
    text-decoration: none;
}